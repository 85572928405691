/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React Base Styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import boxShadow from "assets/theme/functions/boxShadow";

const { fontWeightRegular, size } = typography;
const { borderRadius } = borders;
const { white, black } = colors;

export default {
  base: {
    // display: "inline-flex",
    // justifyContent: "center",
    // alignItems: "center",
    fontSize: size.md,
    //fontWeight: fontWeightRegular,
    // borderRadius: borderRadius,
    borderRadius: pxToRem(3),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,

    whiteSpace: 'nowrap',
    minWidth: 'auto',

    //lineHeight: 1.4,
    //textAlign: "center",
    // textTransform: "uppercase",
    //userSelect: "none",
    // backgroundSize: "150% !important",
    // backgroundPositionX: "25% !important",
    // transition: "all 150ms ease-in",

    // "&:disabled": {
    //   pointerEvent: "none",
    //   opacity: 0.65,
    // },

    // "& .material-icons": {
    //   fontSize: pxToRem(15),
    //   marginTop: pxToRem(-2),
    // },

    "&:hover": {
      //   backgroundColor: white.main,
      boxShadow: "none",
      // boxShadow: boxShadow([0, 8], [10, -5], black.main, 0.2)
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,

    // "& .material-icon, .material-icons-round, svg": {
    //   fontSize: `${pxToRem(12)} !important`,
    // },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(10)} ${pxToRem(16)}`,
    fontSize: size.lg,
    letterSpacing: '2px',

    // "& .material-icon, .material-icons-round, svg": {
    //   fontSize: `${pxToRem(42)} !important`,
    // },
  },

};
