/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { black, white, text, info, primary, secondary, grey } = colors;
const { size } = typography;

export default {
  base: {
    // backgroundColor: white.main,
    minHeight: pxToRem(40),
    // color: text.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    boxShadow: "none",

    border: `solid ${pxToRem(2)}`,

    // "&:hover": {
    //   backgroundColor: white.main,
    // },


    "&:active": {
      boxShadow: "none",
    },

    // "&:active, &:active:focus, &:active:hover": {
    //   opacity: 0.85,
    // },

    // "& .material-icon, .material-icons-round, svg": {
    //   fontSize: `${pxToRem(16)} !important`,
    // },
  },

  primary: {
    backgroundColor: primary.main,
    borderColor: primary.main,
    // color: white.main,

    "&:hover": {
      backgroundColor: primary.focus,
      borderColor: primary.focus,
      color: primary.focusContrastText,
    },

    "&:focus": {
      backgroundColor: primary.dark,
      borderColor: primary.dark,
    },
  },

  secondary: {
    backgroundColor: secondary.main,
    borderColor: secondary.main,
    // color: black.main,

    "&:hover, &:focus": {
      backgroundColor: secondary.focus,
      borderColor: secondary.focus,
      color: secondary.focusContrastText,
    },
  },
};
