import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { styled } from "@mui/system"
import pxToRem from 'assets/theme/functions/pxToRem';

const navItems = ['Home', 'Portfolio', 'Download Resume'];


// function ElevationScroll(props) {
//     const { children } = props;
//     const trigger = useScrollTrigger({
//         disableHysteresis: true,
//         threshold: 0,
//         target: undefined,
//     });

//     return React.cloneElement(children, {
//         elevation: trigger ? 4 : 0,
//     });
// }

// ElevationScroll.propTypes = {
//     children: PropTypes.element.isRequired
// };

const StyledMenuButton = styled(Button)(() => ({
    textTransform: 'none',
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.3px',
    fontSize: pxToRem(15),
}));

export default function ElevateAppBar() {

    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const props = null

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
        console.log(mobileOpen);
    };


    const style = {
        py: 0,
        width: '100%',
    };
    const drawer = (
        <Box onClick={handleDrawerToggle}>
            <List sx={style}>
                {navItems.map((item, index) => (
                    <>
                        <ListItem key={item} disablePadding sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.25)' }}>
                            <ListItemButton sx={{ textAlign: 'left' }}>
                                <ListItemText primary={item} sx={{ color: scrollY > 10 ? 'text.primary' : 'white' }} />
                            </ListItemButton>
                        </ListItem >
                    </>
                ))}
            </List>
        </Box>
    );

    return (
        <Box >
            {/* <ElevationScroll {...props}> */}
            <AppBar component="nav" sx={{
                backgroundColor: scrollY > 10 ? 'white' : 'rgba(255, 255, 255, 0.1)', borderBottom: '1px solid rgba(255, 255, 255, 0.25)', position: 'fixed', zIndex: 1000,
                boxShadow: scrollY > 10 ? 3 : 'none',
            }}>
                <Container sx={{ padding: '0px !important' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block', md: 'none' } }} />
                        <Box component="img" src={scrollY > 10 ? '/assets/images/LogoBlack-3.png' : '/assets/images/LogoWhite-6.png'} alt="logo" loading="lazy" />
                        <Box sx={{ flexGrow: 1 }} />

                        <IconButton
                            color="inherit"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'block', md: 'none' }, color: scrollY > 10 ? 'text.primary' : 'white' }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            <Stack spacing={1} direction='row'>
                                {navItems.map((item) => (
                                    <StyledMenuButton key={item} sx={{ color: scrollY > 10 ? 'text.primary' : 'white' }}>
                                        {item}
                                    </StyledMenuButton>
                                ))}</Stack>
                        </Box>
                    </Toolbar><Box
                        sx={{
                            display: { xs: mobileOpen ? 'none' : 'block', sm: mobileOpen ? 'none' : 'block', md: 'none' }
                        }}
                    >
                        {drawer}
                    </Box>
                </Container>
            </AppBar>

            {/* </ElevationScroll> */}
        </Box >
    );
}