import React, { useState, useEffect } from 'react';

import '@fontsource/open-sans';
import '@fontsource/montserrat';

import "./App.css"

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
import routes from "routes";

import Home from 'pages/Home';

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const [data, setData] = useState('');
  useEffect(() => {
    (async function () {
      const { text } = await (await fetch(`/api/weathermusic`)).json();
      setData(text);
    })();
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>{data}</div>
        < Routes >
          {getRoutes(routes)}
          < Route path="/" element={<Home />} />
          < Route path="*" element={< Navigate to="/" />} />
        </Routes >
      </ThemeProvider>
    </>
  );
}
